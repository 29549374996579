import { useCallback, useMemo, useRef } from "react";
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Spacer,
  Link,
  Heading,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Portal,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useSession, signOut } from "next-auth/react";
import { Magic } from "magic-sdk";
import axios from "axios";
import { useRouter } from "next/router";
import useSubscription from "../hooks/useSubscription";
import NextLink from "next/link";
import Draft from "./Draft";
import ProfileForm from "./ProfileForm";
import useUser from "../hooks/useUser";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default function Nav() {
  const router = useRouter();
  const { data: session } = useSession();
  const { user } = useUser();
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue("gray.100", "gray.900");
  const m = new Magic(process.env.NEXT_PUBLIC_MAGIC_API_KEY || "");

  const trialEndsIn = useMemo(() => {
    if (!user) return;
    const { trialExpires } = user;
    dayjs.extend(relativeTime);
    return dayjs(trialExpires).fromNow();
  }, [user]);

  const { subscription, subscriptionIsLoading, subscriptionIsError } =
    useSubscription();
  const {
    isOpen: draftIsOpen,
    onOpen: draftOnOpen,
    onClose: draftOnClose,
  } = useDisclosure();
  const {
    isOpen: brandIsOpen,
    onOpen: brandOnOpen,
    onClose: brandOnClose,
  } = useDisclosure();
  const btnRef = useRef<HTMLDivElement>(null);

  const modalOnClose = useCallback(() => {
    if (brandIsOpen) {
      brandOnClose();
    }
    if (draftIsOpen) {
      draftOnClose();
    }
  }, [brandIsOpen, draftIsOpen, brandOnClose, draftOnClose]);

  const handleSignout = async () => {
    try {
      await m.user.logout();
      signOut();
    } catch (error) {}
  };

  const handleStripePortal = async () => {
    if (session && session.stripe) {
      const portalUrl = await axios.post(
        `${process.env.NEXT_PUBLIC_VERCEL_URL}/api/stripe/create-portal-link`,
        {
          // @ts-ignore
          customerId: session.stripe.id,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`,
          },
        }
      );
      if (portalUrl) {
        router.push(portalUrl.data);
      }
    }
  };

  return (
    <>
      <Box bg={bg} borderRadius={8}>
        <Flex
          h={16}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={4}
        >
          <Spacer />
          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              <Center ref={btnRef} onClick={brandOnOpen} cursor="pointer">
                <Heading as="h3" fontSize="lg">
                  Brand
                </Heading>
              </Center>
              <Center ref={btnRef} onClick={draftOnOpen} cursor="pointer">
                <Heading as="h3" fontSize="lg">
                  Draft
                </Heading>
              </Center>
              <Button onClick={toggleColorMode}>
                {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              </Button>

              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    src={
                      (session && session.user && session.user.image) ||
                      "https://avatars.dicebear.com/api/male/username.svg"
                    }
                  />
                </MenuButton>
                <MenuList alignItems={"center"} zIndex={2}>
                  <br />
                  <Center>
                    <Avatar
                      size={"2xl"}
                      src={
                        (session && session.user && session.user.image) ||
                        "https://avatars.dicebear.com/api/male/username.svg"
                      }
                    />
                  </Center>
                  <br />
                  <Center>
                    <p>
                      {(session && session.user && session.user.email) ||
                        "anon"}
                    </p>
                  </Center>
                  <br />
                  <MenuDivider />
                  {user && user.trialExpires && (
                    <MenuItem cursor="pointer">
                      <NextLink href="/pricing" passHref>
                        <Link>Trial ends {trialEndsIn}</Link>
                      </NextLink>
                    </MenuItem>
                  )}
                  {subscription &&
                    subscription.data.subscriptions.length > 0 && (
                      <MenuItem cursor="pointer">Active Subscription</MenuItem>
                    )}
                  {subscription && !subscription.data.subscriptions.length && (
                    <MenuItem cursor="pointer">
                      <NextLink href="/pricing" passHref>
                        <Link>Subscribe</Link>
                      </NextLink>
                    </MenuItem>
                  )}
                  {}
                  <MenuItem cursor="pointer" onClick={handleStripePortal}>
                    Billing
                  </MenuItem>
                  <MenuItem cursor="pointer" onClick={handleSignout}>
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
      <Portal>
        <Drawer
          isOpen={draftIsOpen || brandIsOpen}
          placement="right"
          onClose={modalOnClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              {draftIsOpen ? "Save progress" : "Save your brand"}
            </DrawerHeader>
            <DrawerBody>
              {draftIsOpen && <Draft />}
              {brandIsOpen && <ProfileForm />}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Portal>
    </>
  );
}
