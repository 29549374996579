import { Box } from "@chakra-ui/react";

import { RichTextBlock } from "./editor/RichTextBlock";

const Draft = () => {
  return (
    <Box minHeight="80vh" width="full">
      <RichTextBlock />
    </Box>
  );
};

export default Draft;
