import {
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { Template, Field, Tag } from "@prisma/client";
import {} from "@sentry/nextjs";
import NextLink from "next/link";
import { useRouter } from "next/router";
import useTemplates from "../hooks/useTemplates";

const formatPath = (
  path: string,
  templateId: string,
  templates: (Template & { Fields: Field[]; Tags: Tag[] })[]
) => {
  const template = templates.find((c) => c.id === templateId);
  const templatePath = template && template.name.split(" ").join("-");
  return templatePath;
};

const TopNav = () => {
  const { templates, isLoading } = useTemplates();
  const fontColor = useColorModeValue("white", "gray.300");
  const router = useRouter();

  return (
    <Flex alignItems="center">
      <Breadcrumb color={fontColor}>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Heading color={fontColor} as="h1" fontSize="2xl">
              <NextLink href="/" passHref>
                <Link>Templates</Link>
              </NextLink>
            </Heading>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {router.query.id && (
          <BreadcrumbItem>
            {isLoading && (
              <Heading color={fontColor} as="p" fontSize="md">
                ...
              </Heading>
            )}
            {!isLoading && (
              <BreadcrumbLink>
                <Heading color={fontColor} as="p" fontSize="md">
                  {formatPath(
                    router.route,
                    router.query.id as string,
                    templates as any
                  )}
                </Heading>
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </Flex>
  );
};

export default TopNav;
