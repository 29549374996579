import axios from "axios";
import { useSession } from "next-auth/react";
import { useQuery } from "react-query";

const useSubscription = () => {
  const { data: session, status } = useSession();
  const {
    data: subscription,
    isLoading: subscriptionIsLoading,
    isError: subscriptionIsError,
  } = useQuery(
    ["subscription"],
    async () => {
      return axios.post("/api/stripe/checkSubscriptionStatus", {
        // @ts-ignore
        customerId: session?.stripe?.id,
      });
    },
    {
      enabled: !!session?.stripe as boolean,
    }
  );

  return {
    subscription,
    subscriptionIsLoading,
    subscriptionIsError,
  };
};

export default useSubscription;
