import { trpc } from "../utils/trpc";

const useTemplates = () => {
  const { data, isLoading, isError } = trpc.useQuery([
    "get-templates",
    {
      filter: "all",
    },
  ]);

  return {
    templates: data,
    isLoading,
    isError,
  };
};

export default useTemplates;
