import { Box, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";

import TopBar from "../components/TopBar";
import useSubscription from "../hooks/useSubscription";
import TopNav from "../components/TopNav";
import useUser from "../hooks/useUser";

type BaseLayoutProps = {
  children: React.ReactNode;
};

const Base: React.FC<BaseLayoutProps> = ({ children }) => {
  const { data: session, status } = useSession();
  const { user } = useUser();
  const { subscription, subscriptionIsLoading, subscriptionIsError } =
    useSubscription();
  const router = useRouter();
  useEffect(() => {
    if (status !== "loading" && !session) {
      router.push("/auth/signin");
    }
  }, [session, status]);

  useEffect(() => {
    if (subscription && user) {
      if (
        subscription &&
        !subscription.data.subscriptions.length &&
        !user?.isTrial
      ) {
        router.push("/pricing?nosub=true");
      }
    }
  }, [subscription, user]);

  return (
    <Box bg="black">
      {session && (
        <Grid
          height="100vh"
          templateRows="repeat(12, 1fr)"
          templateColumns="repeat(12, 1fr)"
          padding={4}
          gap={4}
        >
          {/* sideBar */}
          {/* <GridItem rowSpan={12} colSpan={2}>
            <Flex
              height="full"
              width="full"
              justifyContent="center"
              alignItems="center"
            >
              <Sidebar />
            </Flex>
          </GridItem> */}
          {/* top bar */}
          <GridItem colSpan={12} rowSpan={1} bg="transparent">
            <SimpleGrid columns={[1, 1, 2]} gap={4}>
              <TopNav />
              <TopBar />
            </SimpleGrid>
          </GridItem>
          {/* main */}
          <GridItem overflowY="scroll" colSpan={12} rowSpan={12}>
            <Box borderRadius={4}>{children}</Box>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

export default Base;
