import React, { useEffect, useMemo, useState } from "react";
import {
  ChakraProvider,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useColorModeValue,
  Progress,
  Skeleton,
} from "@chakra-ui/react";
import useProfile from "../hooks/useProfile";
import { useSession } from "next-auth/react";

const ProfileForm = () => {
  const { data: authProfile, status } = useSession();
  const { profile, updateProfile } = useProfile();
  const [brandName, setBrandName] = useState(
    (profile && profile.brandName) || ""
  );
  const [brandType, setBrandType] = useState(
    (profile && profile.brandType) || ""
  );
  const [brandValues, setBrandValues] = useState(
    (profile && profile.brandValues) || ""
  );
  const [targetAudience, setTargetAudience] = useState(
    (profile && profile.targetAudience) || ""
  );
  const [website, setWebsite] = useState((profile && profile.website) || "");
  const [email, setEmail] = useState((profile && profile.email) || "");
  const [keywords, setKeywords] = useState((profile && profile.keywords) || "");

  useEffect(() => {
    if (profile) {
      setBrandName(profile.brandName as string);
      setBrandType(profile.brandType as string);
      setBrandValues(profile.brandValues as string);
      setTargetAudience(profile.targetAudience as string);
      setWebsite(profile.website as string);
      setEmail(profile.email as string);
      setKeywords(profile.keywords as string);
    }
  }, [profile]);

  const handleSubmit = () => {
    const profile = {
      brandName,
      brandType,
      brandValues,
      targetAudience,
      website,
      email,
      keywords,
    };
    if (authProfile && authProfile.user) {
      updateProfile.mutateAsync({
        ...profile,
        userEmail: authProfile.user.email as string,
      });
    }
  };

  return (
    <Box
      p={4}
      bg={useColorModeValue("white", "gray.800")}
      minHeight="80vh"
      borderRadius={8}
      boxShadow="lg"
    >
      <Stack spacing={2}>
        {updateProfile.isLoading && <p>Loading...</p>}
        {updateProfile.isError && <p>Error</p>}
        {updateProfile.isSuccess && <p>Profile updated successfully!</p>}
        <FormControl>
          <FormLabel>Brand Name</FormLabel>
          {status === "loading" && <Skeleton height="20px" />}
          {status === "authenticated" && (
            <Input
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Brand Type</FormLabel>
          {status === "loading" && <Skeleton height="20px" />}
          {status === "authenticated" && (
            <Input
              value={brandType}
              onChange={(e) => setBrandType(e.target.value)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Brand Values</FormLabel>
          {status === "loading" && <Skeleton height="20px" />}
          {status === "authenticated" && (
            <Input
              value={brandValues}
              onChange={(e) => setBrandValues(e.target.value)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Target Audience</FormLabel>
          {status === "loading" && <Skeleton height="20px" />}
          {status === "authenticated" && (
            <Input
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Website</FormLabel>
          {status === "loading" && <Skeleton height="20px" />}
          {status === "authenticated" && (
            <Input
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Email Address</FormLabel>
          {status === "loading" && <Skeleton height="20px" />}
          {status === "authenticated" && (
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>SEO Keywords</FormLabel>
          {status === "loading" && <Skeleton height="20px" />}
          {status === "authenticated" && (
            <Input
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
            />
          )}
        </FormControl>
        <Button
          variant="outline"
          size="md"
          alignSelf="center"
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("black", "white")}
          _hover={{
            bg: useColorModeValue("black", "white"),
            color: useColorModeValue("white", "black"),
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

export default ProfileForm;
