import { useSession } from "next-auth/react";
import { useMemo } from "react";
import { Profile } from "../types/types";
import { trpc } from "../utils/trpc";

const useProfile = () => {
  const { data } = useSession();
  const profile = trpc.useQuery(
    ["get-profile", { email: data?.user?.email as string }],
    {
      enabled: !!data?.user?.email,
    }
  ).data;
  const updateProfile = trpc.useMutation(["update-profile"]);
  return {
    profile,
    updateProfile,
  };
};

export default useProfile;
