import React, { useCallback, useMemo, useState, useEffect } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, Slate, ReactEditor } from "slate-react";
import { Editor, Transforms, createEditor, Node } from "slate";
import { withHistory } from "slate-history";
import {
  Box,
  Progress,
  Stack,
  useColorModeValue,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Element, Leaf, toggleMark, Toolbar } from "./components";
import debounce from "lodash.debounce";
import useDraft from "../../hooks/useDraft";
import { useSession } from "next-auth/react";
import { MdAirlineSeatIndividualSuite } from "react-icons/md";
import { trpc } from "../../utils/trpc";
import axios from "axios";

// @refresh reset
const HOTKEYS: { [hotkey: string]: string } = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

export interface RichTextBlockProps {
  initialValue?: Node[];
}

export const RichTextBlock: React.FC<RichTextBlockProps> = () => {
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const { data } = useSession();
  const { updateDraft, draft } = useDraft();
  const [value, setValue] = useState<Node[]>();

  useEffect(() => {
    if (data && data.user) {
      axios.get(`/api/getDraft?email=${data.user.email}`).then((res) => {
        if (res.data.draft) {
          setValue(JSON.parse(res.data.draft.content));
          return;
        }
        // There is not draft yet, this is initial state
        setValue([
          {
            type: "paragraph",
            children: [
              {
                text: "",
              },
            ],
          },
        ]);
      });
    }
  }, [data]);

  const debounceMutation = useCallback(
    debounce((update) => {
      const content = JSON.stringify(update);
      if (data && data.user) {
        updateDraft.mutateAsync({
          content,
          email: data.user.email as string,
        });
      }
    }, 500),
    [data, updateDraft]
  );

  const handleChange = async (update: Node[]) => {
    const isAstChange = editor.operations.some(
      (op) => "set_selection" !== op.type
    );

    if (isAstChange) {
      // setValue(update);
      if (debounceMutation) {
        debounceMutation.cancel;
        debounceMutation(update);
      }
    }
  };

  //focus selection
  const [focused, setFocused] = React.useState(false);
  const savedSelection = React.useRef(editor.selection);

  const onFocus = React.useCallback(() => {
    setFocused(true);
    if (!editor.selection && value?.length) {
      Transforms.select(
        editor,
        savedSelection.current ?? Editor.end(editor, [])
      );
    }
  }, [editor]);

  const onBlur = React.useCallback(() => {
    setFocused(false);
    savedSelection.current = editor.selection;
  }, [editor]);

  const divRef = React.useRef<HTMLDivElement>(null);

  const focusEditor = React.useCallback(
    (e: React.MouseEvent) => {
      if (e.target === divRef.current) {
        ReactEditor.focus(editor);
        e.preventDefault();
      }
    },
    [editor]
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
  };

  return (
    <Box
      ref={divRef}
      onMouseDown={focusEditor}
      bg={useColorModeValue("white", "gray.800")}
      borderRadius="lg"
      boxShadow="xl"
    >
      {!value && (
        <Stack>
          <Text>Loading your drafts ...</Text>
          <Progress size="lg" isIndeterminate />
        </Stack>
      )}
      {value && (
        <Slate editor={editor} value={value as any} onChange={handleChange}>
          <Toolbar />
          <Box padding={"15px 5px"} boxShadow="lg" borderRadius={4}>
            <Editable
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Blank canvas, how exciting ❤️ AvvA…"
              spellCheck
              style={{
                minHeight: "300px",
                height: "70vh",
                resize: "vertical",
                overflow: "auto",
                padding: "8px",
              }}
            />
            {updateDraft.isLoading && (
              <Stack>
                <Text>Saving ...</Text>
              </Stack>
            )}
            {updateDraft.isError && <p>Error</p>}
          </Box>
        </Slate>
      )}
    </Box>
  );
};
