import { Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";

// create a type for the props that has children as a prop
type RoundCardProps = {
  children: React.ReactNode;
};

const RoundCard: React.FC<RoundCardProps> = ({ children }) => {
  const bg = useColorModeValue("gray.100", "gray.900");
  return (
    <Box borderRadius={4} boxShadow="lg" padding={4} bg={bg}>
      {children}
    </Box>
  );
};

export default RoundCard;
