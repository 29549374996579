import { useSession } from "next-auth/react";
import { trpc } from "../utils/trpc";

const useDraft = () => {
  const { data } = useSession();
  const draft = trpc.useQuery(
    [
      "get-draft",
      {
        email: data?.user?.email as string,
      },
    ],
    {
      enabled: !!data?.user?.email,
    }
  );
  const updateDraft = trpc.useMutation(["update-draft"]);
  return {
    updateDraft,
    draft,
  };
};

export default useDraft;
