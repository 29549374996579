import { useSession } from "next-auth/react";
import { trpc } from "../utils/trpc";

const useProfile = () => {
  const { data } = useSession();
  const user = trpc.useQuery(
    ["get-user", { email: data?.user?.email as string }],
    {
      enabled: !!data?.user?.email,
    }
  ).data;
  return {
    user,
  };
};

export default useProfile;
